.message-con-bot {
    background-color: #1972f5;
    border-radius: 0 12px 12px 12px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 5px;
    max-width: 300px;
    min-height: 28px;
    padding: 8px 14px 9px;
    width: 78%;
}


