body, html {
  overflow-x: hidden; /* Supprime la barre horizontale sur toute la page */
  max-width: 480px;
  margin: auto;
  box-shadow: 0 0 5px #dfd8f9;
  min-height: 100vh;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-container {
  width: 100% !important;
  height: 100vh ;
}

.react-chatbot-kit-chat-header {
  background-color: #1972F5 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding: 34px!important;
}

.react-chatbot-kit-chat-inner-container, .react-chatbot-kit-chat-message-container {
  height: 90% !important;
  background-color: #ffffff !important;
}

.react-chatbot-kit-chat-message-container {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.react-chatbot-kit-chat-bot-message {
  border-radius: 13px !important;
  /*border-top-left-radius: 0 !important;*/
  background-color: #1972F5 !important;
  box-shadow: 0 0 1px 1px rgb(215 215 215);
  color: white !important;
  font-size: 14px !important;
  padding: 8px 14px 9px!important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  min-height: 28px;
  width: 78%!important;
  max-width: 300px;
  margin-left: 5px!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-right: 14px solid #1972F5!important;
  left: -11px!important;
  top: 0!important;
}

.react-chatbot-kit-user-chat-message-arrow {
  display: none;
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-left: 14px solid #e8ffde!important;
  right: -11px!important;
  top: 0!important;
}

.react-chatbot-kit-user-chat-message {
  border-radius: 5px !important;
  border: 0 !important;
  /*box-shadow: 0 0 1px 1px rgb(215 215 215);*/
  background-color: #f0f2f5!important;
  color: #555 !important;
  font-size: 14px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 4px 10px !important;
}

.react-chatbot-kit-user-chat-message-container .react-chatbot-kit-user-avatar {
  display: none;
}

.react-chatbot-kit-chat-bot-message span {
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  animation: typing 1.8s steps(50, end);
}

.react-chatbot-kit-chat-input-container {
  border-top: 1px #e6e6e6 solid;
  height: 60px !important;
  position: fixed !important;
  z-index: 9;
  max-width: 480px;
}

.react-chatbot-kit-chat-input-form {
  margin: auto;
  width: 92% !important;
  background-color: #ffffff;
  padding: 4px;
  border-radius: 30px;
  height: 40px;
}

.react-chatbot-kit-chat-input {
  border-top: initial!important;
  outline: none;
  font-size: 14px !important;
  padding: 8px 0 !important;
}
.react-chatbot-kit-chat-input::placeholder {
  color: #d5d5d5 !important;
}

.react-chatbot-kit-chat-btn-send {
  border-radius: 5px !important;
  background-color: transparent !important;
  box-shadow: 0 0 !important;
  width: 35px !important;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-chatbot-kit-chat-btn-send svg {
  fill: #2273f1;
  width: 38px;
  height: 38px;
}

.message {
  position: relative;
  margin-bottom: 10px;
}
.message:hover > .timeFloat {
  display: block;
}
.timeFloat {
  display: none;
  padding: 7px;
  font-size: 14px;
  position: absolute;
  top: -24px;
  right: 8px;
  background: white;
  border-radius: 6px;
  white-space: nowrap;
}

/* Personnalise la barre de défilement globalement */
/* Personnalise le curseur de la barre de défilement */
::-webkit-scrollbar {
  width: 0px; /* Largeur de la barre de défilement */
}

/* Personnalise le chemin (track) de la barre de défilement */
::-webkit-scrollbar-track {
  background: #1972f5; /* Couleur de fond du chemin */
  border-radius: 10px; /* Arrondit les coins du chemin */
}

/* Personnalise le pouce (thumb) de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #888; /* Couleur du pouce */
  border-radius: 10px; /* Arrondit les coins du pouce */
}

ul{
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 10px;
}

 ol{
  padding-left: 20px !important;
   word-wrap: break-word;
   max-width: 95%;

  p{
    margin-bottom: 5px;
  }

  ul{
    padding-left: 10px ;
  }
}

p{
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

img{
  height: 100px;
  width: 100px;
}