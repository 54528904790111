.dots {
    background: rgba(155, 155, 155, 0.27);
    width: 27px!important;
    height: 27px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    border-radius: 5px!important;
    transition: background-color .1s ease-in-out!important;
}

.dots span{
    --mask-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGQ9Ik00IDB2MTRIMFYweiIvPjwvY2xpcFBhdGg+PGNsaXBQYXRoIGlkPSJiIj48cGF0aCBkPSJNMiAwYTEuNSAxLjUgMCAwMTEuNSAxLjVWMkExLjUgMS41IDAgMDEyIDMuNWgtLjVBMS41IDEuNSAwIDAxMCAydi0uNUExLjUgMS41IDAgMDExLjUgMHoiLz48L2NsaXBQYXRoPjxnIGNsaXAtcGF0aD0idXJsKCNhKSI+PGcgY2xpcC1wYXRoPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSguMjUgMTAuNSkiPjxwYXRoIGQ9Ik0wIDBoMy41djMuNUgweiIvPjwvZz48ZyBjbGlwLXBhdGg9InVybCgjYikiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC4yNSA1LjI1KSI+PHBhdGggZD0iTTAgMGgzLjV2My41SDB6Ii8+PC9nPjxnIGNsaXAtcGF0aD0idXJsKCNiKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLjI1KSI+PHBhdGggZD0iTTAgMGgzLjV2My41SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)!important;
    -webkit-mask-image: var(--mask-image)!important;
    mask-image: var(--mask-image)!important;
    -webkit-mask-size: contain!important;
    mask-size: contain!important;
    -webkit-mask-repeat: no-repeat!important;
    mask-repeat: no-repeat!important;
    -webkit-mask-position: center!important;
    mask-position: center!important;
    display: inline-block!important;
    width: 4px!important;
    height: 14px!important;
    background-color: #FFFFFF !important;
}

.navBar {
    display: none;
    background: white;
    width: 270px;
    height: 44px;
    position: absolute;
    top: 70px;
    z-index: 9;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 14px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.08),0 3px 1px 0 rgba(0,0,0,.02);
}

.logo-container {
    position: relative;
    display: inline-block;
}

.logo-image {
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.status-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    border: 2px solid white; /* Pour ajouter une bordure blanche autour du point */
}

.restart {
    font-size: 28px;color: #ffffff;cursor: pointer;position: absolute;right: 60px;top: 14px;
}

@media (min-width: 399px) and (max-width: 400px){
    .restart {
        right: 16px;
    }
}