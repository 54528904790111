.message-con {
    border: 0!important;
    border-radius: 5px 0 5px 5px !important;
    font-family: Raleway,sans-serif;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 4px 10px!important;
    max-width: 300px;
}
